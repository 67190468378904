<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
        >
          <el-row :gutter="10">
            <!-- 应急演练 -->
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-input v-model="form.careUnitName" placeholder="请输入" maxlength="30" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  placeholder="请选择标段"
                  v-model="form.bidId"
                  :options="bidOptions"
                  @change="handleBidChage"
                  clearable
                  disabled
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="演练时间" prop="drillTime">
                <el-date-picker
                  v-model="form.drillTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                  disabled
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="参与人数" prop="participantNum">
                <el-input v-model="form.participantNum" placeholder="请输入" maxlength="7" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="负责人" prop="adminName">
                <el-input v-model="form.adminName" placeholder="请输入" maxlength="30" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="联系方式" prop="adminPhone">
                <el-input v-model="form.adminPhone" placeholder="请输入" maxlength="11" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="演练地址" prop="drillAddress">
                <el-input v-model="form.drillAddress" placeholder="请输入" maxlength="30" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="演练内容" prop="drillContent">
                <el-input
                  v-model="form.drillContent"
                  placeholder="请输入"
                  type="textarea"s
                  :rows="4"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="相关照片">
                <ayl-img-upload
                  v-model="relatedPicKey"
                  :imgListLength="50"
                  :imgWidth="'130px'"
                  :imgHeight="'130px'"
                  :isEdit="false"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button v-if="0" type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import addForDialog from "@/mixins/add-for-dialog";
export default {
  mixins: [addForDialog],
  data() {
    return {
      nav: [{ name: "应急演练详情" }],
      submitLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        drillTime: null, //演练时间
        participantNum: null, //参与人数
        adminName: null, //负责人
        adminPhone: null, //联系方式
        drillAddress: null, //演练地址
        drillContent: null, //演练内容
        relatedPicKey: [] //相关照片
      },
      relatedPicKey: [],
      // 必填字段校验
      rules: {
        bidId: {
          required: true,
          message: "必填字段不能为空",
          trigger: "change"
        },
        careUnitName: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        drillTime: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        participantNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        drillAddress: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        }
      }
    };
  },
  async mounted() {
    // 获取详情
    this.form = await this.$api_hw.operationSafetyManagement_emergencyDrillGetDetail(
      {
        id: this.dataFromDialog.id
      }
    );
    this.relatedPicKey = this.form.relatedPicKey ? this.form.relatedPicKey : [];
  },

  methods: {
    // 提交表单
    async submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.relatedPicKey = this.relatedPicKey;
      await this.$api_hw
        .operationSafetyManagement_emergencyDrillUpdate({
          record: formData
        })
        .then(res => {
          BUS.$emit(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT3);
        });
    }
  }
};
</script>

<style lang='sass' scoped>
.content-box .content-main
  padding: 0 20px 20px
.content-box 
  padding: 0
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-textarea.is-disabled .el-textarea__inner
  color: #526a75
</style>